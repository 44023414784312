import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["url"];

  connect() {
    this.cache = new Map();
    this.cacheExpiration = 5 * 60 * 1000; // 5 minutes
    window.addEventListener("beforeunload", this.clearStorageCache.bind(this));
    this.clearStorageCache();
    this.preloadAllProjectFiles();
  }

  async preloadAllProjectFiles() {
    const projectFiles = document.querySelectorAll("[data-project-file]");
    const section = "project-desc";

    const preloadPromises = Array.from(projectFiles).map((fileElement) => {
      const projectFile = fileElement.getAttribute("data-project-file");
      return this.interpolate(projectFile, section);
    });

    try {
      await Promise.all(preloadPromises);
    } catch (error) {
      console.error("Preload error:", error);
    }
  }

  getFileName(event) {
    const projectFile = event.currentTarget.getAttribute("data-project-file");
    const section = "project-desc";
    this.displayCachedContent(projectFile, section);
  }

  displayCachedContent(projectFile, section) {
    const currentPath = window.location.pathname;
    const isDefaultLocale = !currentPath.startsWith("/en/");
    let fullProjectPath;

    if (isDefaultLocale) {
      fullProjectPath = `/projects/${projectFile}`;
    } else {
      fullProjectPath = `/en/projects/${projectFile}`;
    }

    const cacheKey = `${fullProjectPath}-${section}`;
    const cachedContent =
      this.checkMemoryCache(cacheKey) || this.checkStorageCache(cacheKey);

    if (cachedContent) {
      this.urlTarget.innerHTML = cachedContent;
    } else {
      this.interpolate(projectFile, section).then((content) => {
        if (content) {
          this.urlTarget.innerHTML = content;
        }
      });
    }
  }

  async interpolate(projectFile, section) {
    try {
      const currentPath = window.location.pathname;
      const isDefaultLocale = !currentPath.startsWith("/en/");
      let fullProjectPath;

      if (isDefaultLocale) {
        fullProjectPath = `/projects/${projectFile}`;
      } else {
        fullProjectPath = `/en/projects/${projectFile}`;
      }

      const cacheKey = `${fullProjectPath}-${section}`;
      const memoryCached = this.checkMemoryCache(cacheKey);
      if (memoryCached) return memoryCached;

      const storageCached = this.checkStorageCache(cacheKey);
      if (storageCached) {
        this.setMemoryCache(cacheKey, storageCached);
        return storageCached;
      }

      const response = await fetch(fullProjectPath);
      if (!response.ok) {
        throw new Error(
          `Fetch failed: ${response.status} ${response.statusText}`,
        );
      }

      const partialHTML = await response.text();
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = partialHTML;
      const sectionContent = tempContainer.querySelector(`section#${section}`);

      if (sectionContent) {
        const content = sectionContent.innerHTML;
        this.setMemoryCache(cacheKey, content);
        this.setStorageCache(cacheKey, content);
        return content;
      } else {
        console.error(
          `Section ${section} not found in response from ${fullProjectPath}`,
        );
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return `<p class="error">Failed to load content: ${error.message}</p>`;
    }
  }

  checkMemoryCache(key) {
    const cached = this.cache.get(key);
    if (!cached) return null;
    const { timestamp, content } = cached;
    if (Date.now() - timestamp > this.cacheExpiration) {
      this.cache.delete(key);
      return null;
    }
    return content;
  }

  setMemoryCache(key, content) {
    this.cache.set(key, {
      content,
      timestamp: Date.now(),
    });
  }

  checkStorageCache(key) {
    try {
      const cached = localStorage.getItem(key);
      if (!cached) return null;
      const { timestamp, content } = JSON.parse(cached);
      if (Date.now() - timestamp > this.cacheExpiration) {
        localStorage.removeItem(key);
        return null;
      }
      return content;
    } catch (error) {
      console.error("Storage cache error:", error);
      return null;
    }
  }

  setStorageCache(key, content) {
    try {
      localStorage.setItem(
        key,
        JSON.stringify({
          content,
          timestamp: Date.now(),
        }),
      );
    } catch (error) {
      console.error("Storage cache error:", error);
    }
  }

  clearStorageCache() {
    localStorage.clear();
  }

  reset() {
    this.urlTarget.innerHTML = "";
  }

  disconnect() {
    this.cache.clear();
    window.removeEventListener(
      "beforeunload",
      this.clearStorageCache.bind(this),
    );
  }
}
