
        import * as module0 from '../images/card-icon-craques.svg';import * as module1 from '../images/card-icon-go-local.svg';import * as module2 from '../images/card-icon-guide-me.svg';import * as module3 from '../images/go_local.png';import * as module4 from '../images/go_local.svg';import * as module5 from '../images/guide_me.png';import * as module6 from '../images/guide_me.svg';import * as module7 from '../images/ong.jpg'
        const modules = {
            "../images/card-icon-craques.svg": module0,
            "../images/card-icon-go-local.svg": module1,
            "../images/card-icon-guide-me.svg": module2,
            "../images/go_local.png": module3,
            "../images/go_local.svg": module4,
            "../images/guide_me.png": module5,
            "../images/guide_me.svg": module6,
            "../images/ong.jpg": module7,
        };
        export default modules;
      