import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target"];

  getHref(event) {
    const href = event.currentTarget.closest("a").getAttribute("href");
    const customText = this.getCustomText(href);
    this.targetTarget.textContent = customText;
    console.log(href);
  }

  resetHref() {
    this.targetTarget.textContent = "";
  }

  getCustomText(href) {
    const customText = {
      "https://github.com/pedro-hga": "gh project list --owner pedro",
      "http://www.golocal.lol": "xdg-open http://www.golocal.lol",
      "http://www.craquesdavida.org": "xdg-open http://www.craquesdavida.org",
      "https://pedro-hga.notion.site/": "xdg-open http://pedro-hga.notion.site",
      "https://pedro-hga.notion.site/cv":
        "xdg-open http://pedro-hga.notion.site",
      "https://guide-me-app-c2d673a36c32.herokuapp.com/":
        "xdg-open http://www.guide-me.com",
      "https://github.com/pedro-hga/go-local":
        "gh repo view pedro-hga/go-local",
      "https://github.com/pedro-hga/guide-me":
        "gh repo view pedro-hga/guide-me",
      "https://www.linkedin.com/in/pedrohga":
        "xdg-open https://www.linkedin.com/in/pedrohga",
      "https://wa.me/5511986786862?text=Eu%20vi%20seu%20portf%C3%B3lio%20e%20gostaria%20de%20dizer%20oi%21":
        "xdg-open https://web.whatsapp.com",
      "https://wa.me/5511986786862?text=I%20saw%20your%20portfolio%20and%20wanted%20to%20say%20hi%21":
        "xdg-open https://web.whatsapp.com",
      // pt-br
      "/": "cd ~/home",
      "/about": "nvim sobre-mim.md",
      "/projects": "cd ~/home/projetos",
      "/projects/go_local/": "cd go_local/ && nvim .",
      "/projects/guide_me/": "cd guide_me/ && nvim .",
      "/projects/craques_da_vida/": "cd craques_da_vida/ && nvim .",
      "/public-files/CV-PT.pdf": "wget https://pedro_hga.com/CV.pdf",
      "/contact_me": "ruby contato.rb",
      "mailto:pedro_hga@proton.me?subject=Olá!&body=Escreva%20seu%20texto%20aqui.":
        "ruby contato.rb",
      // en
      "/en": "cd ~/home",
      "/en/about": "nvim README.md",
      "/en/projects/go_local/": "cd go_local/ && nvim .",
      "/en/projects/guide_me/": "cd guide_me/ && nvim .",
      "/en/projects/craques_da_vida/": "cd craques_da_vida/ && nvim .",
      "/en/projects": "cd ~/home/projects",
      "/en/contact_me": "ruby contact_me.rb",
      "mailto:pedro_hga@proton.me?subject=Hi!&body=Write%2your%20email%20here.":
        "ruby contact_me.rb",
    };
    return customText[href] || "";
  }
}
