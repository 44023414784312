import "$styles/index.scss";
import "$styles/syntax-highlighting.css";
import { Application } from "@hotwired/stimulus";

import images from "../images/**/*.{jpg,jpeg,png,svg}";
Object.entries(images).forEach((image) => image);

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

// console.info("Bridgetown is loaded!")

window.Stimulus = Application.start();

import controllers from "./controllers/**/*.{js,js.rb}";
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename
      .replace("./controllers/", "")
      .replace(/[_-]controller\..*$/, "")
      .replace("_", "-")
      .replace("/", "--");

    Stimulus.register(identifier, controller.default);
  }
});
